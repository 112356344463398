import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {navigate} from 'gatsby';

const ProjectCardWrapper = styled.div`
  position: relative;
  width: 360px;
  height: 250px;
  background-color: ${props => props.cardBackColor};
  border-radius: 26px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 0.4s ease-out;
  &:hover{
    transform: translateY(10px);
    // cursor:pointer;
  }
  margin: 8px 8px 16px 8px;
  @media (max-width: 768px){
    margin-top: 258px;
    &:hover{
      transform: none;
    }
  }
`

const ProjectCardText = styled.p`
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 10%;
  bottom: 40%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  -moz-user-select:none;
  -webkit-user-select:none;
  color: ${props => props.color};
`

const ProjectImage = styled.div`
  position: absolute;
  border-radius: 26px;
  width: 360px;
  height: 250px;
  transition: 0.2s ease-out;
  ${ProjectCardWrapper}:hover &{
    opacity: 0.05;
  }
  @media (max-width: 768px){
    top: -258px;
    ${ProjectCardWrapper}:hover &{
      opacity: 1;
    }
  }
`

const InformationWrapper = styled.div`
  z-index: 1;
  opacity: 0;
  transition: 0.2s ease-in;
  ${ProjectCardWrapper}:hover & {
    opacity: 1;
  }
  @media (max-width: 768px){
    opacity: 1;
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  top: 180px;
  display: flex;
  justify-content: center;
`

const ProjectName = styled.p`
  position: relative;
  font-size: 2em;
  text-align: center;
  color: ${props => props.color};
`

const BuiltWithText = styled.p`
  text-align: left;
  padding-left: 24px;
  margin-bottom: 0px;
  color: ${props => props.color};
`

const BuiltWithBadges = styled.div`
  padding: 4px 8px 8px 16px;
`

const BuiltBadge = styled.img`
  padding: 8px 8px 8px 8px;
`

const TitleIcon = styled(FontAwesomeIcon)`
    padding-right: 8px;
`

function projectPage(project){
  navigate('/' + project.toLowerCase())
}

export default function ProjectCard(props) {
  return (
    <ProjectCardWrapper cardBackColor={props.backgroundColor} 
    // onClick = {() => projectPage(props.projectName)}
    >
      <ProjectImage>
        <ProjectName color={props.titleColor}>
          <span>
            <TitleIcon icon={props.icon}></TitleIcon>
          </span>
          {props.projectName}
        </ProjectName>
        <BuiltWithText color={props.titleColor}>Built with:</BuiltWithText>
        <BuiltWithBadges>
          {Object.keys(props.badges).map(function(key, index){
            return (
              <BuiltBadge src={props.badges[key]} alt="" width="40" height="40"></BuiltBadge> 
            );
          })}
        </BuiltWithBadges>
      </ProjectImage>
      <InformationWrapper>
        <ProjectCardText color={props.titleColor}> {props.cardText} </ProjectCardText>
        <ButtonWrapper>{props.children}</ButtonWrapper>
      </InformationWrapper>
    </ProjectCardWrapper>
  )
}

// module.exports = {
//     FlipCard: FlipCard,
//     ProjectCard: ProjectCard,
// };
