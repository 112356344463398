import React from "react"
import styled from "styled-components"

const GreetHeader = styled.h1`
    text-align: center;
    margin 0 0 1.45rem;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
`

const SubtextWrapper = styled.p`
    text-align: center;
    margin 0 0 1.45rem;
    line-height: 1.1;
    text-rendering: optimizeLegibility;
    font-size: larger;
`

const GreetWrapper = styled.div`
    padding-top: 3rem;
`

function GreetSubtext(props){
    return (
        <SubtextWrapper>{props.text}</SubtextWrapper>
    );
}

export default function GreetComponent(props) {
    return (
        <GreetWrapper>
            <GreetHeader>
                {props.text}
            </GreetHeader>
            <GreetSubtext text={props.subtext}></GreetSubtext>
        </GreetWrapper>
    );
  }