import React from "react"
import styled from "styled-components"
import RectButton from "../components/circle_button"
import ProjectCard from "../components/project_card"
import GreetComponent from "../components/greet_header"
import Badge from "../components/badge"
import Navbar from "../components/navbar"
import BadgeLink from "../components/badge_link"

import "../components/global.css"
import { faGithub, faLinkedinIn, faHackerrank, faDocker } from '@fortawesome/free-brands-svg-icons' 
import { faBookmark, faSpider} from '@fortawesome/free-solid-svg-icons' 
import selenium from "../../static/icons/selenium.svg"
import git from "../../static/icons/git.svg"
import html from "../../static/icons/html.svg"
import python from "../../static/icons/python.svg"
import javascript from "../../static/icons/javascript.svg"
import firefox from "../../static/icons/firefox.svg"
import heroku from "../../static/icons/heroku.svg"
import sqlite from "../../static/icons/sqlite.svg"
import flask from "../../static/icons/flask.svg"
import cs50 from "../../static/icons/cs50.svg"
import picture from "../../static/picture.jpg"

const CardLayout = styled.div`
  width: 66.6667%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px auto;
  @media (max-width: 768px){
    width: 100%;
    // transform: scale(0.9);
  }
`
const BadgeLinkLayout = styled.div`
  text-align:center;
  padding-top: 2.5rem;
  padding-bottom 2rem;
`
const AvatarImg = styled.img `
  height:60px;
  width:60px;
  border-radius:50%;
  background:black;
  position:absolute;
  top:65px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.125);
  background-color: #fff;
  right:calc(50% - 30px);
`
const SingleButtonLayout = styled.div`
  display: flex;
  justify-content: center;
`

const OuterContainerWrapper = styled.div`
  margin-top: 110px;
`

export default function Home() {
  return (
    <div>
      <Navbar></Navbar>
      <OuterContainerWrapper>
        <AvatarImg src={picture}></AvatarImg>
        <GreetComponent text={"Hi, I'm Bayram KAYA"} subtext="I'm a Software Engineer who likes to automate, abstract, design computer programs."></GreetComponent>
        <CardLayout>
          <ProjectCard backgroundColor="#1b1b1b" titleColor="#8bf5ff" icon={faBookmark} projectName="Bookmark" cardText="Pdf management and progress tracker software that runs locally on a browser." badges={{0:python,1:selenium,2:javascript,3:html,4:git,5:firefox}} >
            <RectButton icon={faGithub} href="https://github.com/ByK95/bookmark" name="Source" />
            {/* <RectButton icon={faDocker} href="#" name="Demo" /> */}
          </ProjectCard>
          <ProjectCard backgroundColor="#d6d6d6" icon={faSpider} projectName="Crowley" cardText="Web application that collects data from other websites." badges={{0:heroku,1:python,2:flask,3:javascript,4:html,5:cs50,6:sqlite}} >
              <RectButton icon={faGithub} href="https://github.com/ByK95/Crowley" name="Source" />
              <RectButton icon={faDocker} href="https://bykcs50final.herokuapp.com/" name="Demo" />
          </ProjectCard>
        </CardLayout>
        <BadgeLinkLayout>
          <BadgeLink href="https://www.linkedin.com/in/bayram-kaya/" icon={faLinkedinIn}/>
          <BadgeLink href="https://github.com/ByK95" icon={faGithub}/>
          <BadgeLink href="https://www.hackerrank.com/byrmkaya97" icon={faHackerrank}/>
        </BadgeLinkLayout>
      </OuterContainerWrapper>
    </div>
  )
}
