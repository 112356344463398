import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OutherWrapper = styled.a`
  width: 125px;
  float: right;
  padding-right: ${props => props.padding_right};
  text-decoration: none;
  color: #000;
`

const RoundedWrapper = styled.div`
  width: 120px;
  height: 48px;
  background: ${props => props.theme.background};
  border-radius: 48px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.hover_background};
  }
`

RoundedWrapper.defaultProps = {
  theme: {
    background: "#c4fb6d",
    hover_background: "#76ead7",
    textColor: "#D8416B",
  },
}

const ButtonText = styled.span`
  position: relative;
  top: 17%;
  font-family: Roboto;
  font-weight: normal;
  font-size: 18px;
  color: ${props => props.theme.textColor};
`

const ButtonIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  padding-left: 8px;
`

export default function RectButton(props) {
  return (
    <OutherWrapper padding_right={props.pl} href={props.href}>
      <RoundedWrapper>
        <ButtonText> {props.name} 
          <ButtonIcon icon={props.icon} />
        </ButtonText>
      </RoundedWrapper>
    </OutherWrapper>
  )
}
